<template>
  <div class="row noprint">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 form-inline">
          <input
            type="text"
            @input="debounceSearch()"
            v-model="lSearchText"
            class="form-control form-control-sm mb-3 w-50"
            placeholder="Suggestions search..."
          />
          <select
            placeholder="Select Category"
            class="form-control form-control-sm mb-3 ms-3 w-25"
            v-model="selectedCategory"
          >
            <option value="0">View All</option>
            <option
              v-for="category in categories"
              :key="category.name"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>
          <button class="btn mb-3 ms-2" @click="resetSearch()">
            <i class="fas fa-sync-alt"></i> Reset
          </button>
          <table class="table table-hover noprint">
            <thead>
              <th scope="col" class="center">Suggested Text</th>
            </thead>
            <tbody class="suggestionsTable">
              <tr
                v-for="d in filteredCategory"
                :key="d.id"
                @click="selectAutoSuggestion(d)"
              >
                <td v-html="d.content" class="textItem"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["TextEditor", "searchText", "dtas", "item"],
  data() {
    return {
      lSearchText: "",
      categories: [],
      selected: 0,
      selectedCategory: 0,
      newCategory: "",
      showNewCat: false,
    };
  },
  mounted() {
    this.getAutoSuggestionCategories();
    this.lSearchText = this.searchText;
  },
  computed: {
    filteredCategory: function () {
      var items = [];
      items = this.dtas.filter((item) => {
        if (
          item.category_id == this.selectedCategory ||
          this.selectedCategory == 0
        ) {
          return true;
        }
        return false;
      });
      return items;
    },
  },
  methods: {
    debounceSearch: _.debounce(function () {
      this.$parent.searchForAutoSuggestions(this.lSearchText);
    }, 800),
    resetSearch() {
      this.selectedCategory = 0;
      this.lSearchText = "";
      this.$parent.searchForAutoSuggestions("");
    },
    changeCategory(id) {
      this.selectedCategory = id;
      console.log(id);
    },
    selectAutoSuggestion(item) {
      if (item.delta) {
        console.log(item.delta);
        let currentDelta = this.TextEditor.quill.getContents();
        let combinedDelta = currentDelta.concat(JSON.parse(item.delta));
        this.TextEditor.quill.setContents(combinedDelta);
      } else {
        this.item.data += item.content;
      }
      this.$EventBus.$emit("saveTemplate");
    },
    getAutoSuggestionCategories() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/documents/dtas-categories")
        .then(({ data }) => {
          this.categories = data;
        });
    },
    isTheItemSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return "border-danger";
      }
      return "border-light";
    },
    selectThisItem() {
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
  },
};
</script>

<style scoped>
.padleft {
  padding-left: 20px;
}

.suggestionsTable {
  font-size: 12px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #03006b white;
  cursor: pointer;
}
/* Works on Chrome/Edge/Safari */
.suggestionsTable::-webkit-scrollbar {
  width: 12px;
}
.suggestionsTable::-webkit-scrollbar-track {
  background-image: white;
}
.suggestionsTable::-webkit-scrollbar-thumb {
  background-color: #413dc2;
  border-radius: 20px;
  border: 3px solid #03006b;
}
.center {
  text-align: center;
}
</style>