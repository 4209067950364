<template>
  <div
    class="modal fade top-z"
    id="attachModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalTitle"
    aria-hidden="true"
    data-backdrop="false"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered"
      :class="miniview ? 'video-mode' : ''"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitle">Attach Resources</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="ready1 && ready2">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <input
                    class="form-control form-control-sm mb-2"
                    type="text"
                    placeholder="Search resources.."
                    v-model="searchQuery"
                  />
                  <div class="table-responsive resTable w-100">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Resource</th>
                          <th>Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="r in filteredResources"
                          :key="r.id"
                          :class="isAttached(r) ? 'bg-info text-white' : ''"
                        >
                          <td>#{{ r.id }}</td>
                          <td>{{ r.title }}</td>
                          <td>{{ r.model.replace("Resource", "") }}</td>
                          <td>
                            <button
                              class="btn btn-xs me-1"
                              v-if="!isAttached(r)"
                            >
                              <i
                                class="fa fa-plus"
                                @click="selectResource(r)"
                                v-tooltip:bottom="'Add Resource'"
                              ></i>
                            </button>
                            <button
                              class="btn btn-xs me-1"
                              @click="selectResource(r)"
                              v-else
                            >
                              <i
                                class="fa fa-minus text-white"
                                :v-tooltip:bottom="'Remove Resource'"
                              ></i>
                            </button>
                            <button
                              class="btn btn-xs me-1"
                              @click="viewResource(r)"
                              v-if="!(r.model === 'FolderResource')"
                            >
                              <i
                                class="fas fa-eye"
                                v-tooltip:bottom="'Preview Resource'"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="closeModal()">
            <i class="far fa-share me-1"></i> Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ["resources", "miniview"],
  data() {
    return {
      AllResources: [],
      AvailableResources: [],
      searchQuery: "",
      client_resources: [],
      client: this.$parent.client,
      ready1: false,
      ready2: false,
    };
  },
  methods: {
    isShared(r) {
      return this.client_resources.some((res) => res.resource_id === r.id);
    },
    isAttached(r) {
      return this.resources.some((res) => res.id === r.id);
    },
    isFolderEmpty(data, r) {
      return !data.some((res) => res.folder === r.id);
    },
    addResource(r) {
      if (this.resources.find((res) => res.id === r.id)) {
        return;
      }
      this.$EventBus.$emit("addResource", r);
      if (r.folder) {
        let folder = this.AllResources.find((item) => item.id === r.folder);
        if (!folder) {
          console.log("Folder not found");
          return;
        }
        this.addResource(folder);
      }
    },
    removeResource(r) {
      this.$EventBus.$emit("removeResource", r);
      if (r.folder) {
        var folderResource = this.resources.find((res) => res.id === r.folder);
        if (
          this.isAttached(folderResource) &&
          this.isFolderEmpty(this.resources, folderResource)
        ) {
          this.removeResource(folderResource);
        }
      }
    },
    addFolder(r) {
      let vm = this;
      this.AllResources.forEach((item) => {
        if (item.folder === r.id) {
          vm.addResource(item);
          if (item.model === "FolderResource") {
            this.addFolder(item);
          }
        }
      });
    },
    removeFolder(r) {
      let vm = this;
      this.AllResources.forEach((item) => {
        if (item.folder === r.id) {
          vm.removeResource(item);
          if (item.model === "FolderResource") {
            this.removeFolder(item);
          }
        }
      });
    },
    selectResource(r) {
      if (!this.isAttached(r)) {
        this.addResource(r);
        if (r.model === "FolderResource") {
          this.addFolder(r);
        }
      } else {
        this.removeResource(r);
        if (r.model === "FolderResource") {
          this.removeFolder(r);
        }
      }
    },
    viewResource(resource) {
      open(resource.resource.file, "_blank");
    },
    closeModal() {
      $("#attachModal").modal("hide");
    },
    openModal() {
      $("#attachModal").prependTo("body");
      $("#attachModal").modal("show");
    },
    addFolderItems(data, folderId, depth) {
      data.forEach((item) => {
        if (item.folder === folderId) {
          for (let i = 0; i < depth; i++) {
            item.title = " - " + item.title;
          }
          if (item.model !== "FolderResource") {
            this.AvailableResources.push(item);
          } else if (!this.isFolderEmpty(this.AllResources, item)) {
            this.AvailableResources.push(item);
            this.addFolderItems(data, item.id, depth + 1);
          }
        }
      });
    },
  },
  mounted() {
    this.$axios
      .get(process.env.VUE_APP_API_URL + "/resources/api")
      .then(({ data }) => {
        this.AllResources = data;
        this.AvailableResources = []; //data.filter((item) => item.folder === 0);
        let vm = this;
        this.AllResources.forEach((item) => {
          if (item.folder === 0) {
            if (item.model !== "FolderResource") {
              vm.AvailableResources.push(item);
            } else if (!vm.isFolderEmpty(vm.AllResources, item)) {
              vm.AvailableResources.push(item);
              vm.addFolderItems(data, item.id, 1);
            }
          }
        });
        this.ready1 = true;
      });

    this.$axios
      .get(
        process.env.VUE_APP_API_URL +
          "/clients/api/" +
          this.client.id +
          "/resources"
      )
      .then(({ data }) => {
        this.client_resources = data;
        this.ready2 = true;
      });
  },
  computed: {
    filteredResources() {
      return this.AvailableResources.filter((r) => {
        return (
          r.title.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
          !this.isShared(r)
        );
      });
    },
  },
};
</script>

<style scoped>
.top-z {
  z-index: 9999;
}

.video-mode {
  position: absolute !important;
  left: 5%;
  z-index: 10000;
  width: 40%;
}

.resTable {
  font-size: 12px;
  max-height: 400px;
  display: inline-block;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #03006b white;
}
/* Works on Chrome/Edge/Safari */
.resTable::-webkit-scrollbar {
  width: 8px;
}
.resTable::-webkit-scrollbar-track {
  background-image: white;
}
.resTable::-webkit-scrollbar-thumb {
  background-color: #413dc2;
  border-radius: 20px;
  border: 3px solid #03006b;
}
</style>
