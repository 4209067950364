<template>
  <div
    class="mb-3 border p-3"
    style="position: relative"
    @click="selectThisItem"
    :class="isTheItemSelected(item.id)"
  >
    <span
      style="position: absolute; bottom: 0rem; right: 0.15rem; z-index: 999"
      v-tooltip.bottom="'Delete this item'"
    >
      <button
        class="btn btn-xs btn-light noprint"
        @click="deleteItemFromCol(item)"
        v-if="isSelected(item.id)"
      >
        <i class="far fa-trash"></i>
      </button>
    </span>
    <div :class="'text-' + item.options.align">
      <img
        :src="item.img_url"
        :alt="item.img_alt"
        :width="item.options.scale + '%'"
      />
      <vue-dropzone
        class="noprint"
        v-show="!item.img_url"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-complete="uploaded"
      ></vue-dropzone>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  props: ["item", "colKey"],
  data() {
    return {
      dropzoneOptions: {
        url:
          process.env.VUE_APP_API_URL +
          "/documents/" +
          this.$stash.loadedTemplate.id +
          "/upload-image",
        thumbnailWidth: 150,
        maxFilesize: 24,
        headers: {
          Authorization: "Bearer " + this.$store.getters['auth/token'],
        },
        dictDefaultMessage:
          "<i class='far fa-upload fa-4x text-primary'></i><br><br>Click here to select a file from your computer or drag n' drop a file to begin uploading",
      },
    };
  },
  methods: {
    uploaded(res) {
      var url = JSON.parse(res.xhr.response).url;
      this.item.img_url = url;
      this.$EventBus.$emit("saveTemplate");
    },
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return "border-danger";
      }
      return "border-light";
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    deleteItemFromCol(item) {
      if (!confirm("Are you sure you wish to remove this item?")) {
        return;
      }
      var data = {
        colKey: this.colKey,
        item: item,
      };
      var indexOfRowToRemoveFrom = this.$stash.templateItems
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$stash.currentSelectedRow.id);

      var templateItems = this.$stash.templateItems;
      var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

      var indexToRemove = rowToAddTo[data.colKey].items
        .map(function (x) {
          return x.id;
        })
        .indexOf(data.item.id);

      rowToAddTo[data.colKey].items.splice(indexToRemove, 1);
      this.$EventBus.$emit("saveTemplate");
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
};
</script>

<style>
</style>