import { render, staticRenderFns } from "./TextArea.vue?vue&type=template&id=23783a07&scoped=true&"
import script from "./TextArea.vue?vue&type=script&lang=js&"
export * from "./TextArea.vue?vue&type=script&lang=js&"
import style0 from "./TextArea.vue?vue&type=style&index=0&id=23783a07&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23783a07",
  null
  
)

export default component.exports