<template>
   <div class="row noprint">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 form-inline">
          <input
          type="text"
          @input="debounceSearch"
          v-model="search"
          class="form-control form-control-sm mb-3 w-50"
          placeholder="Test search..."
        />
        <select
            placeholder="Select Category"
            class="form-control form-control-sm mb-3 ms-3 w-25"
            v-model="selectedCategory"
          >
            <option value="0">View All</option>
            <option
              v-for="category in categories"
              :key="category.name"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>
          <button class="btn mb-3 ms-2" @click="resetSearch()">
            <i class="fas fa-sync-alt"></i> Reset
          </button>
        <table class="table table-hover noprint">
          <thead>
            <th scope="col" class="center">Find Test</th>
            <th></th>
          </thead>
          <tbody class="fixedScrollTable w-100">
            <tr
              class="d-flex "
              v-for="test in filteredByCategory"
              :key="test.id"
              @click="selectTest(test)"
            >
              <td class="col-3 longTextWrap">{{ test.code }}</td>
              <td class="col-3 longTextWrap">{{ test.supplier }}</td>
              <td class="col-6 longTextWrap">{{ test.description | truncate(80, '...')}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
    props: ["item"],
  data() {
    return {
      tests: [],
      categories: [],
      selectedCategory: 0,
      toggleFeature: false,
      search: "",
    };
  },
  methods: {
    debounceSearch: _.debounce(function () {
      this.findTest();
    }, 800),
    resetSearch() {
      this.selectedCategory = 0;
      this.search = "";
      this.findTest();
    },
    changeCategory(id) {
      this.selectedCategory = id;
    },
    getCategories() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tests/categories").then(({ data }) => {
        this.categories = data.data;
      });
    },
    selectTest(test) {
      this.$EventBus.$emit("addTest", test, this.item.id);
      this.$EventBus.$emit("saveTemplate");
    },
    getTests() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tests").then(({ data }) => {
        this.tests = data;
      });
    },
    findTest() {
      if (this.search.length > 0) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/tests/search", {
            search: this.search,
          })
          .then(({ data }) => {
            this.tests = data;
          });
      } else {
        this.tests = [];
        this.getTests();
      }
    },
  },
  mounted: function () {
    this.getCategories();
    this.getTests();
  },
  computed: {
    filteredByCategory: function () {
      var items = [];
      items = this.tests.filter((item) => {
        if (item.category_id == this.selectedCategory) {
          return true;
        }

        if (this.selectedCategory == 0) {
          return true;
        }

        return false;
      });
      return items;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    },
  },
};
</script>

<style scoped>
.longTextWrap {
  word-break: break-word;
}

.fixedScrollTable {
  font-size: 12px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #03006b white;
  cursor: pointer;
}
/* Works on Chrome/Edge/Safari */
.fixedScrollTable::-webkit-scrollbar {
  width: 12px;
}
.fixedScrollTable::-webkit-scrollbar-track {
  background-image: white;
}
.fixedScrollTable::-webkit-scrollbar-thumb {
  background-color: #413dc2;
  border-radius: 20px;
  border: 3px solid #03006b;
}
</style>