<template>
  <div>
    <div class="row noprint" v-if="!miniview">
      <div class="col btn-group mb-1" aria-label="Toolbar">
        <button
          class="btn btn-sm btn-secondary"
          @click="printPreview"
          v-tooltip:bottom="'Preview as document and print'"
        >
          <i class="far fa-print me-2"></i>Preview
        </button>
        <button
          class="btn btn-sm btn-secondary"
          @click="saveTemplate"
          v-tooltip:bottom="'Save a draft of this document'"
        >
          <i class="far fa-check me-2"></i>Save
        </button>
        <button
          v-if="client"
          class="btn btn-sm btn-secondary"
          @click="saveToClientProfile"
          v-tooltip:bottom="'Save this as a PDF to the client profile!'"
          :disabled="busy"
        >
          <busy :visible="busy" button />
          <i
            v-if="!busy"
            class="far me-2 fa-paper-plane"
          />
          Send
        </button>
      </div>
    </div>
    <div class="row noprint" v-if="client && !miniview">
      <div class="col btn-group" aria-label="Toolbar">
        <button
          class="btn btn-sm btn-secondary mb-3"
          @click="Undo()"
          v-tooltip:bottom="'Undo last action'"
        >
          <i class="fas fa-undo me-2"></i>Undo
        </button>

        <!--Disabled until resource linkup is complete - {resource} shortcut must be used instead-->
        <button
          class="btn btn-sm btn-secondary mb-3"
          @click="AttachResource()"
          v-tooltip:bottom="'Attach a resource'"
        >
          <i class="fas fa-paperclip me-2"></i>Attach
          <span v-if="resources.length > 0">( {{ resources.length }} )</span>
        </button>
        <attach-resource ref="attachResource" :resources="resources" />
      </div>
    </div>
    <div class="me-2" v-if="miniview">
      <button
        class="btn btn-info mb-3 d-block"
        @click="printPreview"
        v-tooltip:bottom="'Print Preview'"
      >
        <i class="far fa-print"></i>
      </button>
      <button
        class="btn btn-info mb-3 d-block"
        @click="saveTemplate"
        v-tooltip:bottom="'Save a draft'"
      >
        <i class="far fa-check"></i>
      </button>
      <button
        v-if="client"
        class="btn btn-info mb-3 d-block"
        @click="saveToClientProfile"
        v-tooltip:bottom="'Save this as a PDF to the client profile!'"
        :disabled="busy"
      >
        <busy :visible="busy" button />
        <i
          v-if="!busy"
          class="far me-2 fa-paper-plane"
        />
      </button>
      <button
          class="btn btn-secondary mb-3 d-block notification"
          @click="AttachResource()"
          v-tooltip:bottom="'Attach a resource'"
        >
          <i class="fas fa-paperclip"></i>
        </button>
        <attach-resource :client="vclient" ref="attachResource" :resources="resources" :miniview="true"  />
    </div>
  </div>
</template>
<script>
import AttachResource from "./toolbar-partials/AttachResource";

export default {
  props: ["client", "template", "history", "resources", "miniview"],
  components: { AttachResource },
  data: function () {
    return {
      busy: false,
      vclient: this.client,
    };
  },
  methods: {
    Undo() {
      this.$EventBus.$emit("undo");
    },
    saveTemplate() {
      var silent = false;
      this.$EventBus.$emit("saveTemplate", silent);
    },
    clearCurrentSelection() {
      this.$EventBus.$emit("clearSelectedTemplateItems");
    },
    AttachResource() {
      this.$refs.attachResource.openModal();
    },
    printPreview() {
      this.clearCurrentSelection();
      this.saveTemplate();

      this.$nextTick(() => {
        const element = window.document.getElementById("page");

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = window.open(
          "",
          "_blank",
          "toolbar=0,scrollbars=0,status=0",
          true
        );
        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Document Preview</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          cb();
        }, 1000);
      });
    },
    saveToClientProfile() {
      var confirmed = confirm(
        "You are about to share this health report with " +
          this.client.name +
          ". This will appear in their files as a PDF. Are you sure you wish to continue?"
      );
      if (confirmed) {
        this.busy = true;
        this.$EventBus.$emit("saveTemplate");
        const vm = this;
        setTimeout(function () {
          vm.$axios
            .get(
              process.env.VUE_APP_API_URL +
                "/documents/" +
                vm.template.id +
                "/store-as-pdf"
            )
            .then(({ data }) => {
              vm.$EventBus.$emit("alert", data);
            })
            .catch((e) => {
              console.log(e);
              if (!vm.miniview) {
                vm.$router.push("/clients/" + vm.client.id + "#files-tab");
              }
              vm.busy = false;
            })
            .then(() => {
              if (!vm.miniview) {
                vm.$router.push("/clients/" + vm.client.id);
              }
              vm.busy = false;
            });
        }, 3000);
        if (this.resources && this.resources.length > 0) {
          this.$EventBus.$emit("shareResources");
        }
      }
    },
  },
};
</script>
