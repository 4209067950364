<template>
  <div>
    <div class="card border-0">
      <div class="card-body">
        <a
          href="https://support.swandoola.com/faqs/how-to-create-a-health-report-template"
          target="_blank"
          class="btn btn-primary btn-block btn"
        ><i class="far fa-question me-2"></i>Health Report How-To</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>