<template>
  <div>
  <a class="dropdown-item" draggable="true"
      @dragstart="dragStart"
      :options="{group: 'menuItems'}"
      style="cursor: move"><i :class="'w-25 fa ' + item.icon"></i> {{item.name}}</a>
    </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    dragStart(event) {
      this.createNewId();
      this.$stash.itemBeingDragged = this.item;
    },
    createNewId() {
      this.item.id = this._uid + new Date().getUTCMilliseconds();
      this.$stash.unsavedChanges = true;
    }
  },
  mounted() {
    this.item.id = this._uid + new Date().getUTCMilliseconds();
  }
};
</script>

<style>
</style>