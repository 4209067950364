<template>
    <div class="overlay-resource noprint">
        <table class="table table-hover noprint" style="width:50%">
            <thead>
              <th scope="col" class="center">Select Resource to Link:</th>
            </thead>
            <tbody class="resources-table">
              <tr v-for="r in resources" :key="r.id" @click="AddResource(r)">
                <td >{{r.title}} </td>
                <td><i class="fa fa-eye me-2" v-tooltip.bottom="'Open file in new tab'" @click="downloadResource(r)"></i></td>
              </tr>
            </tbody>
          </table>
    </div>
</template>
<script>
export default {
  props: ['item'],
    data: function () {
    return {
      resources: [],
    }
  },
  methods:  {
    downloadResource(resource) {
      window.open(resource.resource.file, "_blank");
    },
    AddResource(resource) {
      this.$EventBus.$emit("addResource", resource);
      this.item.data = this.item.data.replace('{resource}', resource.title);
    },
  },
  mounted: function () {
      this.$axios.get(process.env.VUE_APP_API_URL + "/resources/api").then(({ data }) => {
        this.resources = data;
      });
  }
}
</script>
<style scoped>
.overlay-resource {
    background-color: rgba(255,255,255,1);
    border-color: #2196F3!important;
    padding: 0.01em 16px;
    border-left: 6px solid #ccc!important;
    z-index: 2;
}
.resources-table {
    cursor: pointer;
}
</style>