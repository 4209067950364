<template>
  <div class="row noprint">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-2 mx-auto">
          <div>
            <h5 class="grid-title center">
              <i class="fa fa-filter"></i> Categories
            </h5>
            <hr />
            <div class="btn-group-f">
              <button
                v-for="category in categories"
                :key="category.name"
                @click="changeCategory(category.id, category.name)"
              >
                {{ category.name }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-10">
          <input
            type="text"
            @input="searchForAutoSuggestions(searchText)"
            v-model="searchText"
            class="form-control form-control-sm mb-3"
            placeholder="Suggestions search..."
          />
          <table class="table table-hover noprint">
            <thead>
              <th scope="col" class="center">Suggested Text</th>
            </thead>
            <tbody class="suggestionsTable">
              <tr
                v-for="d in filteredCategory"
                :key="d.id"
                @click="selectAutoSuggestion(d)"
              >
                <td v-html="d.content" class="textItem"></td>
              </tr>
            </tbody>
          </table>
          <div class="container saverTool">
            <div class="btn btn-primary savebtn">
              <button
                class="btn btn-xs btn-primary"
                @click="saveAutoSuggestion"
                v-tooltip.bottom="
                  'Adds this passage of text to the auto-suggestion library, when you start typing this text next time the passage will be available for you to click and pre-populate.'
                "
              >
                <i class="far fa-save me-2"></i>Save to Auto Suggestions
              </button>
              <select class="btn-xs" v-model="selected">
                <option
                  v-for="category in categories"
                  :key="category.name"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
                <option value="-1">NEW CATEGORY</option>
              </select>
            </div>
            <div v-if="selected == '-1'" class="grid-item">
              <input
                type="text"
                placeholder="Category Name"
                v-model="newCategory"
              />
              <button @click="addAutoSuggestionCategories()">
                Add Category
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["textEntry"],
  data() {
    return {
      dtas: [],
      categories: [],
      selected: 0,
      selectedCategory: 0,
      newCategory: "",
      showNewCat: false,
      searchText: "",
    };
  },
  mounted() {
    this.getAutoSuggestionCategories();
    this.searchForAutoSuggestions(this.searchText);
  },
  computed: {
    filteredCategory: function () {
      var items = [];
      items = this.dtas.filter((item) => {
        if (item.category_id == this.selectedCategory) {
          return true;
        }

        if (this.selectedCategory == 0 && item.category_id == 0) {
          return true;
        }

        return false;
      });
      return items;
    },
  },
  methods: {
    changeCategory(id, name) {
      if (name == "Uncategorised") {
        this.selectedCategory = 0;
      } else {
        this.selectedCategory = id;
      }
    },
    selectAutoSuggestion(d) {
      this.textEntry.data += d.content;
      this.$EventBus.$emit("saveTemplate");
    },
    saveAutoSuggestion() {
      if (this.selected >= 0) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/documents/create-dtas", {
            content: this.textEntry.data,
            type: "text",
            category_id: this.selected,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
          });
      } else {
        this.$EventBus.$emit("alert", "Error: You must select a category.");
      }
    },
    searchForAutoSuggestions(item) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/documents/search-dtas", {
          search: item,
        })
        .then(({ data }) => {
          this.dtas = data;
        });
    },
    getAutoSuggestionCategories() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/documents/dtas-categories")
        .then(({ data }) => {
          this.categories = data;
        });
    },
    addAutoSuggestionCategories() {
      if (this.newCategory.length > 0) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/documents/dtas-categories", {
            name: this.newCategory,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.getAutoSuggestionCategories();
          });
      }
    },
    isTheItemSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return "border-danger";
      }
      return "border-light";
    },
    selectThisItem() {
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
  },
};
</script>

<style scoped>
.padleft {
  padding-left: 20px;
}

.textItem {
  font-size: 12px;
}

.suggestionsTable {
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #03006b white;
  cursor: pointer;
}
/* Works on Chrome/Edge/Safari */
.suggestionsTable::-webkit-scrollbar {
  width: 12px;
}
.suggestionsTable::-webkit-scrollbar-track {
  background-image: white;
}
.suggestionsTable::-webkit-scrollbar-thumb {
  background-color: #413dc2;
  border-radius: 20px;
  border: 3px solid #03006b;
}

.grid-item {
  margin-top: 10px;
}
.grid-title {
  margin-top: 20px;
}
.center {
  text-align: center;
}
.btn-group-f button {
  background-image: linear-gradient(to right, #03006b, #413dc2) !important;
  color: white;
  padding: 5px 12px;
  font-size: 80%;
  cursor: pointer;
  width: 80%;
  margin-left: 10px;
  display: block;
}
.btn-group-f button:not(:last-child) {
  border-bottom: none;
}
.btn-group-f button:hover {
  background-color: #03006b;
}
.saverTool {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
</style>