<template>
  <div>
    <div class="card border-0 shadow-none">
      <div
        class="card-body bg-secondary"
        @click="clearCurrentSelection"
        @dragover.prevent
        @drop="dropItem"
        :options="{group: 'menuItems'}"
      >

        <div class="page" id="page">
          <div class="row unmargin" v-if="$stash.templateItems.length > 0">
            <div class="col unmargin">
              <draggable
                :list="$stash.templateItems"
                element="ul"
                class="list-group list-group-flush reordering unmargin"
                @start="doThis"
                @end="doThat"
                :options="{group: 'actualItems'}"
              >
                <li
                  class="list-group-item border-0 unmargin"
                  v-for="item in $stash.templateItems"
                  :key="item.id"
                >
                  <!-- <div > -->
                  <dt-row
                    v-if="item && item.type == 'row'"
                    :row="item"
                    :client="client"
                  ></dt-row>
                  <!-- </div> -->
                </li>
              </draggable>
            </div>
          </div>
          <div class="row my-5 text-center" v-if="$stash.templateItems.length == 0">
            <div class="col">
              <i class="far fa-info-circle fa-10x mb-3 text-primary"></i>
              <p>To begin building your health report, drag a row on to the page.</p>
              <p>Add an item from the right into your row, such as a clinic logo or a passage of text</p>
              <p>Add one item per row, or add columns to rows to enhance the layout of your health report</p>
              <p>Rows may be re-ordered by dragging them up and down the page</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DtRow from "./form-items/DtRow";
import draggable from "vuedraggable";
export default {
  props: ["client"],
  data() {
    return {
      items: [],
      storeBusy: false
    };
  },
  methods: {
    closeLoadedTemplate() {
      this.$EventBus.$emit("closeLoadedTemplate");
      this.clearCurrentSelection();
    },
    storePdf() {
      this.$EventBus.$emit("saveTemplate");
      this.storeBusy = true;
      var url = "/documents/" + this.$stash.loadedTemplate.id + "/store-as-pdf";

      this.$axios.post(process.env.VUE_APP_API_URL + url).then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        this.storeBusy = false;
      });
    },
    draggedOver(i) {
      console.log(i);
    },
    doThis(i) {},
    doThat() {
      this.updateTemplate();
    },
    updateTemplate() {
      this.$EventBus.$emit("saveTemplate");
    },
    clearCurrentSelection() {
      this.$EventBus.$emit("saveTemplate");
      this.$stash.currentSelectedRow = null;
      this.$stash.currentSelectedColKey = null;
      this.$stash.currentSelectedCol = null;
      this.$stash.currentSelectedItem = null;
    },
    dropItem(event) {
      if (this.$stash.itemBeingDragged) {
        var dragFromItems = this.$parent.draggableItems;

        var itemToBeAdded = _.cloneDeep(this.$stash.itemBeingDragged);

        if (itemToBeAdded.type == "row") {
          this.$stash.currentSelectedColKey = null;
          this.$stash.currentSelectedCol = null;
          this.$stash.currentSelectedItem = null;
          this.$stash.currentSelectedRow = null;
        }

        if (this.$stash.currentSelectedRow) {
          // We should check if there are other cols in here
          // Default has 1 column
          var indexOfRowToAddTo = this.$stash.templateItems
            .map(function(x) {
              return x.id;
            })
            .indexOf(this.$stash.currentSelectedRow.id);

          var templateItems = this.$stash.templateItems;
          var rowToAddTo = templateItems[indexOfRowToAddTo].items;
          // If the row contains a col & the incoming item is not a col add it to the col
          if (
            rowToAddTo[this.$stash.currentSelectedColKey].type == "col" &&
            itemToBeAdded.type != "col"
          ) {
            rowToAddTo[this.$stash.currentSelectedColKey].items.push(
              itemToBeAdded
            );
          } else if (itemToBeAdded.type == "col") {
            if (rowToAddTo.some(e => e.id === itemToBeAdded.id)) {
              itemToBeAdded.id = new Date().getUTCMilliseconds();
            }

            // Add a col to the row
            // Check if the row contains 3 or less cols
            if (rowToAddTo.length == 1 || rowToAddTo.length == 2) {
              var index = rowToAddTo.push(itemToBeAdded) - 1;
              this.$stash.currentSelectedCol = itemToBeAdded;
              this.$stash.currentSelectedColKey = index;
            }
          }
        } else {
          // If its just a row, add it
          this.$stash.templateItems.push(itemToBeAdded);
          this.$stash.currentSelectedRow = itemToBeAdded;
          this.$stash.currentSelectedCol = itemToBeAdded.items[0];
          this.$stash.currentSelectedColKey = 0;

          this.scrollToRow(itemToBeAdded.id);
        }

        // Ready up for the next one
        this.$stash.itemBeingDragged = null;
        this.updateTemplate();
      }
      this.updateTemplate();
    },
    scrollToRow(id) {
      console.log(id);
      this.$nextTick(() => {
        this.$scrollTo("#row-" + id, 600);
      });

      // document.getElementById("row" + id).scrollIntoView();
    }
  },
  mounted() {},
  components: {
    DtRow,
    draggable
  }
};
</script>

<style>
</style>