<!-- MOVING USERS TO ULLIST -->
<template>
  <div
    class="mb-3 border p-3"
    style="position: relative"
    @click="selectThisItem"
    :class="isTheItemSelected(item.id)"
  >
    <span
      style="position: absolute; bottom: 0rem; right: 0.15rem; z-index: 999"
      v-tooltip.bottom="'Delete this item'"
    >
      <button
        class="btn btn-xs btn-light noprint"
        @click.stop="deleteItemFromCol(item)"
        v-if="isSelected(item.id)"
      >
        <i class="far fa-trash"></i>
      </button>
    </span>
    <div :class="'text-' + item.options.align" class="list-print">
      <h5 class="mb-2" v-show="item.label" v-if="!isSelected(item.id)">
        {{ item.label }}
      </h5>
      <input
        v-else
        class="form-control form-control-sm mb-3"
        type="text"
        v-model="item.label"
      />

      <div>
        <div v-if="item.options.list == 'bullet'">
          <ul class="ms-4 noprint">
            <li v-for="(i, key) in item.data" :key="key" class="mb-3">
              <div class="row" v-if="isSelected(item.id)">
                <div class="col">
                  <input
                    :key="key"
                    type="text"
                    v-model="item.data[key]"
                    class="form-control form-control-sm noprint"
                  />
                </div>

                <div class="col-auto noprint">
                  <button class="btn btn-xs btn-light" @click="orderlist(key, key-1, item.data)" :disabled="!(key !== 0)"><i class="fas fa-caret-up"></i></button>
                  <button class="btn btn-xs btn-light" @click="orderlist(key, key+1, item.data)" :disabled="!(key !== item.data.length-1)"><i class="fas fa-caret-down"></i></button>
                  <button
                    class="btn btn-xs btn-light"
                    @click="removeItemFromList(key)"
                  >
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
              <span v-else>{{ item.data[key] }}</span>
            </li>
          </ul>

          <ul class="ms-4 show-when-printing">
            <li v-for="(i, key) in item.data" :key="'hidden-' + key">
              {{ i }}
            </li>
          </ul>
        </div>

        <!-- Numbered Lists -->
        <div v-else>
          <ol class="ms-4 show-when-printing">
            <li v-for="(i, key) in item.data" :key="'hidden-' + key">
              {{ i }}
            </li>
          </ol>

          <ol class="ms-4 noprint">
            <li v-for="(i, key) in item.data" :key="key" class="mb-3">
              <div class="row" v-if="isSelected(item.id)">
                <div class="col">
                  <input
                    :key="key"
                    type="text"
                    v-model="item.data[key]"
                    class="form-control form-control-sm"
                  />
                </div>

                <div class="col-auto noprint">
                  <button class="btn btn-xs btn-light" @click="orderlist(key, key-1, item.data)" :disabled="!(key !== 0)"><i class="fas fa-caret-up"></i></button>
                  <button class="btn btn-xs btn-light" @click="orderlist(key, key+1, item.data)" :disabled="!(key !== item.data.length-1)"><i class="fas fa-caret-down"></i></button>
                  <button
                    class="btn btn-xs btn-light"
                    @click="removeItemFromList(key)"
                  >
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
              <span v-else>{{ item.data[key] }}</span>
            </li>
          </ol>
        </div>
        <!-------------------->

        <form @submit.prevent="addItemToList" class="noprint" v-if="isSelected(item.id)">
          <input
            type="text"
            v-model="newListItem"
            class="form-control form-control-sm mt-3"
            placeholder="New Item..."
            @input="searchForAutoSuggestions"
          />
        </form>

        <ul class="list-group list-group-flush noprint" v-if="dtas.length > 0">
          <li
            class="list-group-item list-group-item-action"
            v-for="d in dtas"
            :key="d.id"
            @click="selectAutoSuggestion(d)"
          >
            <div v-html="d.content"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "colKey"],
  data() {
    return {
      dtas: [],
      newListItem: "",
    };
  },
  watch: {
    /*
    //Removed to reduce server requests
    item: {
      handler: function (val, oldVal) {
        console.log("firing");
        this.$EventBus.$emit("saveTemplate");
      },
      deep: true,
    },
    */
  },
  methods: {
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    orderlist(index, to, items)
    {
      let cache = items[index];
      items[index] = items[to];
      items[to] = cache;
    },
    selectAutoSuggestion(d) {
      this.item.data.push(d.content);
      this.dtas = [];
      this.newListItem = "";
      this.$EventBus.$emit("saveTemplate");
    },
    saveAutoSuggestion() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/documents/create-dtas", {
          content: this.item.data,
          type: "text",
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    searchForAutoSuggestions() {
      console.log("searching");
      if (this.newListItem.length > 3) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/documents/search-dtas", {
            search: this.newListItem,
            type: "list",
          })
          .then(({ data }) => {
            this.dtas = data;
          });
      } else {
        this.dtas = [];
      }
    },
    addItemToList() {
      this.item.data.push(this.newListItem);
      this.newListItem = "";
      this.$EventBus.$emit("saveTemplate");
    },
    removeItemFromList(index) {
      this.item.data.splice(index, 1);
      this.$EventBus.$emit("saveTemplate");
    },
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      var tclass = this.isSelected(itemId) ? "border-danger" : "border-light";
      return tclass;
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    deleteItemFromCol(item) {
      if (
        !confirm(
          "Are you sure you wish to remove this item and all its contents?"
        )
      ) {
        return;
      }
      var data = {
        colKey: this.colKey,
        item: item,
      };
      var indexOfRowToRemoveFrom = this.$stash.templateItems
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$stash.currentSelectedRow.id);

      var templateItems = this.$stash.templateItems;
      var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

      var indexToRemove = rowToAddTo[data.colKey].items
        .map(function (x) {
          return x.id;
        })
        .indexOf(data.item.id);

      rowToAddTo[data.colKey].items.splice(indexToRemove, 1);
      this.$EventBus.$emit("saveTemplate");
    },
  },
};
</script>

<style>
</style>