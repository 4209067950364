<template>
  <div
    class="mt-3 border p-2"
    style="position: relative;"
    @click="selectThisItem"
    :class="item.options.border > 0 ? 'border-' + item.options.border + ' border-' + item.options.bordercol : isTheItemSelected(item.id)
    + ' ' + item.options.pageBreak"
  >
    <span
      style="position: absolute; bottom: 0rem; right: 0.15rem; z-index: 999"
      v-tooltip.bottom="'Delete this item'"
    >
      <button
        class="btn btn-xs btn-light noprint"
        @click="deleteItemFromCol(item)"
        v-if="isSelected(item.id)"
      >
        <i class="far fa-trash"></i>
      </button>
    </span>
    <div :class="'text-' + item.options.align">
      <h5 class="mb-2" v-show="item.label" v-if="!isSelected(item.id)">
        {{ item.label }}
      </h5>
      <input
        v-else
        class="form-control form-control-sm mb-3 noprint"
        type="text"
        v-model="item.label"
      />

      <div v-if="isSelected(item.id)">
        <div class="row">
          <div class="col">
            <div>
              <vue-editor
                ref="TextEditor"
                v-model="item.data"
                class="bg-white hr-font"
                :editor-toolbar="customToolbar"
                :editorOptions="editorSettings1"
                @focus="updateTemplate"
                @selection-change="onSelectionChange"
                v-if="isSelected(item.id)"
                useCustomImageHandler @image-added="handleImageAdded"
              ></vue-editor>
            </div>

            <div class="row text-start noprint" v-if="isSelected(item.id)">
              <div class="col">
                <button
                  class="btn btn-xs btn-info mb-2 me-2"
                  v-on:click="toggleSuggestions = !toggleSuggestions"
                  v-tooltip.bottom="
                    'Toggle the suggestion feature (phraseology).'
                  "
                >
                  <i class="fas fa-dot-circle me-2 pull-right"></i>
                  <span v-show="toggleSuggestions">Hide</span>
                  <span v-show="!toggleSuggestions">Search Suggestions</span>
                </button>
                <button
                  class="btn btn-xs btn-info mb-2"
                  v-on:click="toggleAdd = !toggleAdd"
                  v-tooltip.bottom="'Add text to suggestions (phraseology)'"
                >
                  <i class="fas fa-dot-circle me-2 pull-right"></i>
                  <span v-show="toggleAdd">Hide</span>
                  <span v-show="!toggleAdd">Add Suggestion</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <phraseology-suggestions
          v-show="toggleSuggestions"
          @selection-change="onSelectionChange"
          :TextEditor="$refs.TextEditor"
          :dtas="dtas"
          :item="item"
        />
        <div v-show="toggleAdd">
          <p v-if="!selectedDelta || selectedDelta.length <= 0">
            <small
              ><i
                >Tip: Select text from area above to add to phraseology.</i
              ></small
            >
          </p>
          <vue-editor
            ref="SelectionPreview"
            class="bg-white m-4 addLibraryBox"
            :editorOptions="editorSettings2"
            disabled
          ></vue-editor>
          <div class="col-md-4 offset-md-4">
            <select class="form-control form-control-sm" v-model="selected">
              <option
                v-for="category in categories"
                :key="category.name"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>

            <button
              class="form-control form-control-sm btn-primary"
              @click="saveAutoSuggestion"
              placeholder="Select text from text area above to add to suggestions."
              v-tooltip.bottom="
                'Adds this passage of text to the suggestion library, allowing you to quickly re-use this in the future.'
              "
            >
              <i class="far fa-save me-2"></i>Save to Suggestions (Phraseology)
            </button>
          </div>
        </div>
      </div>
      <div class="ql-editor non-editor hr-font" v-html="item.data" v-else></div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import PhraseologySuggestions from "./suggestions/Phraseology";
import ImageResize from "quill-image-resize-module--fix-imports-error";

Quill.register("modules/imageResize", ImageResize);

export default {
  props: ["item", "colKey"],
  data() {
    return {
      dtas: [],
      toggleSuggestions: false,
      toggleAdd: false,
      categories: [],
      selected: 0,
      selectedDelta: "",
      selectedText: "",
      customToolbar: [
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [ 'link', 'image' ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["clean"],
      ],
      editorSettings1: {
        modules: {
        imageResize: {
          displaySize: true
          }
        }
      },
      editorSettings2: {
        modules: {
          toolbar: false,
        },
      },
      searchText: "",
    };
  },
  components: {
    PhraseologySuggestions,
  },
  methods: {
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      var tclass = this.isSelected(itemId) ? "border-danger" : "border-light";
      return tclass;
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    deleteItemFromCol(item) {
      if (!confirm("Are you sure you wish to remove this item and all its contents?")) {
        return;
      }
      var data = {
        colKey: this.colKey,
        item: item,
      };
      var indexOfRowToRemoveFrom = this.$stash.templateItems
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$stash.currentSelectedRow.id);

      var templateItems = this.$stash.templateItems;
      var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

      var indexToRemove = rowToAddTo[data.colKey].items
        .map(function (x) {
          return x.id;
        })
        .indexOf(data.item.id);

      rowToAddTo[data.colKey].items.splice(indexToRemove, 1);
      this.$EventBus.$emit("saveTemplate");
    },
    updateTemplate() {
      this.$EventBus.$emit("saveTemplate");
    },
    searchForAutoSuggestions(item) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/documents/search-dtas", {
            search: item,
          })
          .then(({ data }) => {
            this.dtas = data;
          });
    },
    getAutoSuggestionCategories() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/documents/dtas-categories")
        .then(({ data }) => {
          this.categories = data;
        });
    },
    saveAutoSuggestion() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/documents/create-dtas", {
          content: this.selectedText,
          delta: this.selectedDelta,
          type: "text",
          category_id: this.selected,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.toggleAdd = false;
        });
    },
    onSelectionChange(range) {
      if (range && range.length > 0) {
        let content = this.$refs.TextEditor.quill.getContents(range.index, range.length);
        this.$refs.SelectionPreview.quill.setContents(content);
        let text = this.$refs.TextEditor.quill.getText(range.index, range.length);
        this.selectedDelta = JSON.stringify(content);
        this.selectedText = text;
      }
    },
    strippedContent(content) {
      let regex = /(<([^>]+)>)/gi;
      return content.replace(regex, "");
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {

      var formData = new FormData();
      formData.append("file", file);


      this.$axios({
        url:
        process.env.VUE_APP_API_URL +
          "/documents/" +
          this.$stash.loadedTemplate.id +
          "/upload-image",
        method: "POST",
        data: formData
      })
        .then(result => {
          console.log(result.data);
          let url = result.data.url;
          Editor.insertEmbed(cursorLocation, "image", url);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted: function () {
    this.getAutoSuggestionCategories();
    this.searchForAutoSuggestions("");
  },
};
</script>

<style scoped>
.addLibraryBox {
  height: 100px;
  overflow-y:scroll;
}

.non-editor {
  min-height:50px !important;
}
</style>