<template>
  <div
    class="mb-3 border p-3"
    style="position:relative"
    @click.stop="selectThisItem"
    :class="isTheItemSelected(item.id)"
  >
    <span
      style="position: absolute; bottom: 0rem; right: .15rem; z-index: 999;"
      v-tooltip.bottom="'Delete this item'"
    >
      <button class="btn btn-xs btn-light noprint" @click="deleteItemFromCol(item)" v-if="isSelected(item.id)">
        <i class="far fa-trash"></i>
      </button>
    </span>
    <div v-if="client">
      <h6 class="mb-0">{{ clientName }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "colKey", "client"],
  computed: {
    clientName() {
      if (this.client) {
        this.item.client = this.client;
        return this.client.name;
      }
      return this.item.name;
    }
  },
  methods: {
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return "border-danger";
      }
      return "border-light";
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    deleteItemFromCol(item) {
      var data = {
        colKey: this.colKey,
        item: item
      };
      var indexOfRowToRemoveFrom = this.$stash.templateItems
        .map(function(x) {
          return x.id;
        })
        .indexOf(this.$stash.currentSelectedRow.id);

      var templateItems = this.$stash.templateItems;
      var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

      var indexToRemove = rowToAddTo[data.colKey].items
        .map(function(x) {
          return x.id;
        })
        .indexOf(data.item.id);

      rowToAddTo[data.colKey].items.splice(indexToRemove, 1);
      this.$EventBus.$emit("saveTemplate");
    }
  }
};
</script>

<style>
</style>