<template>
  <div
    :class="[howManyCols(), isTheColSelected(colKey)]"
    class="border p-2 h-100 unmargin"
    @click="selectThisCol(colKey, col)"
    style="min-height: 100px"
  >
    <span
      v-if="
        this.row.items.length <= 3 &&
        this.row.items.length > 1 &&
        col.items.length == 0
      "
      style="position: absolute; top: 0rem; right: 0.15rem; z-index: 999"
    >
      <button class="btn btn-xs btn-light noprint" @click="deleteCol(colKey)">
        <i class="far fa-trash"></i>
      </button>
    </span>
    <draggable element="ul" :list="col.items" class="unmargin">
      <li
        class="list-group-item border-0 unmargin"
        v-for="(c, cKey) in col.items"
        :key="cKey"
      >
        <ul-list
          v-if="c.type == 'ul'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
        ></ul-list>
        <ol-list
          v-if="c.type == 'ol'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
        ></ol-list>
        <text-area
          v-if="c.type == 'text'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
        ></text-area>

        <text-passage
          v-if="c.type == 'text-passage'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
        ></text-passage>
        <heading
          v-if="c.type == 'heading'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
        ></heading>
        <clinic-logo
          v-if="c.type == 'logo'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
        ></clinic-logo>

        <image-section
          v-if="c.type == 'image'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
        ></image-section>
        <practitioner-name
          v-if="c.type == 'practitioner-name'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
        ></practitioner-name>
        <client-name
          v-if="c.type == 'client-name'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
          :client="client"
        ></client-name>

        <supplements-table
          v-if="c.type == 'supplements-table'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
          :client="client"
        ></supplements-table>

        <tests-table
          v-if="c.type == 'tests-table'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
          :client="client"
        ></tests-table>

        <page-break
          v-if="c.type == 'page-break'"
          :item="c"
          :key="cKey"
          :col-key="colKey"
          :client="client"
        ></page-break>

        <AutoResource
          v-if="c.data != null && c.data.indexOf('{resource}') > -1"
          :item="c"
        />
      </li>
    </draggable>
  </div>
</template>

<script>
import UlList from "./items/UlList";
import OlList from "./items/OlList";
import TextArea from "./items/TextArea";
import TextPassage from "./items/TextPassage";
import ClinicLogo from "./items/ClinicLogo";
import ImageSection from "./items/Image";
import PractitionerName from "./items/PractitionerName";
import ClientName from "./items/ClientName";
import Heading from "./items/Heading";
import SupplementsTable from "./items/SupplementsTable";
import PageBreak from "./items/PageBreak";
import draggable from "vuedraggable";
import AutoResource from "./items/AutoResource";
import TestsTable from "./items/TestsTable.vue";

export default {
  props: ["colKey", "col", "row", "client"],
  methods: {
    saveChange(event) {
      console.log(event);
      //set(this.col.items, event.oldIndex, event.newIndex);
      this.$EventBus.$emit("saveTemplate");
    },
    checkMove: function (e) {
      //window.console.log(e);
    },
    selectThisItem(item) {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = item;
    },
    deleteCol(colKey) {
      if (this.$stash.currentSelectedRow) {
        var indexOfRowToRemoveFrom = this.$stash.templateItems
          .map(function (x) {
            return x.id;
          })
          .indexOf(this.$stash.currentSelectedRow.id);

        var templateItems = this.$stash.templateItems;
        var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

        if (rowToAddTo.length > 1) {
          rowToAddTo.splice(colKey, 1);
        }

        this.$EventBus.$emit("saveTemplate");
      }
    },
    selectThisCol(colKey, col) {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedCol = col;
      this.$stash.currentSelectedColKey = colKey;
      if (this.$stash.currentSelectedCol.items.length == 0) {
        this.$stash.currentSelectedItem = null;
      }
    },
    checkColSelection(colId) {
      if (this.$stash.currentSelectedColKey === colId) {
        if (this.$stash.currentSelectedRow) {
          if (this.$stash.currentSelectedRow.id === this.row.id) {
            return true;
          }
        }
      }
      return false;
    },
    isTheColSelected(colId) {
      if (this.$stash.currentSelectedColKey === colId) {
        if (this.$stash.currentSelectedRow) {
          if (this.$stash.currentSelectedRow.id === this.row.id) {
            return "border-info";
          }
        }
      }

      return "border-light";
    },
    howManyCols() {
      if (this.row.items.length == 1) {
        return "col-print-12";
      }
      if (this.row.items.length == 2) {
        return "col-print-6";
      }
      if (this.row.items.length == 3) {
        return "col-print-4";
      }
    },
  },
  components: {
    UlList,
    OlList,
    TextArea,
    TextPassage,
    ClinicLogo,
    ImageSection,
    Heading,
    PractitionerName,
    ClientName,
    SupplementsTable,
    PageBreak,
    AutoResource,
    TestsTable,
    draggable,
  },
};
</script>

<style scoped>
/* Note: Bootstraps responsive columns have unexpected results on printing */
/* Todo: Can be switched to use these on print only if HR becomes unresponsive on small devices */
.col-print-1 {
  width: 8%;
  float: left;
}
.col-print-2 {
  width: 16%;
  float: left;
}
.col-print-3 {
  width: 25%;
  float: left;
}
.col-print-4 {
  width: 33%;
  float: left;
}
.col-print-5 {
  width: 42%;
  float: left;
}
.col-print-6 {
  width: 50%;
  float: left;
}
.col-print-7 {
  width: 58%;
  float: left;
}
.col-print-8 {
  width: 66%;
  float: left;
}
.col-print-9 {
  width: 75%;
  float: left;
}
.col-print-10 {
  width: 83%;
  float: left;
}
.col-print-11 {
  width: 92%;
  float: left;
}
.col-print-12 {
  width: 100%;
  float: left;
}
</style>