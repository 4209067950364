<template>
  <div>
    <div class="card border-0 shadow-sm">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <label for>Health Report Template Title</label>
            <input
              type="text"
              v-model="title"
              class="form-control"
              placeholder="An amazing title..."
            />
            <button
              class="btn btn-primary mt-4 float-end"
              @click="createTemplate"
            >
              <i class="far fa-arrow-right me-2"></i>Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  methods: {
    createTemplate() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/documents", {
          title: this.title,
        })
        .then(({ data }) => {
          this.$stash.loadedTemplate = data.template;
          this.$stash.templateItems = data.template.data;
          this.$EventBus.$emit("alert", data);
          this.$router.push({name: "HealthReportsIndex"})
        });
    },
  },
};
</script>

<style>
</style>