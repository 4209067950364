<template>
  <div
    class="mb-3 border p-2"
    style="position: relative"
    @click="selectThisItem"
    :class="isTheItemSelected(item.id)"
  >
    <span
      style="position: absolute; bottom: 0rem; right: 0.15rem; z-index: 999"
      v-tooltip.bottom="'Delete this item'"
    >
      <button
        class="btn btn-xs btn-light noprint"
        @click="deleteItemFromCol(item)"
        v-if="isSelected(item.id)"
      >
        <i class="far fa-trash"></i>
      </button>
    </span>
    <div
      :class="'text-' + item.options.align"
    >
      <h5 class="mb-2" v-show="item.label" v-if="!isSelected(item.id)">
        {{ item.label }}
      </h5>
      <input
        v-else
        class="form-control form-control-sm mb-3 noprint"
        type="text"
        v-model="item.label"
      />
      <div>
        <div class="row">
          <div class="col">
            <div class="container-fluid sw-table health-report hr-font hr-table">
              <div class="sw-row d-flex fw-bold">
                  <div class="col-2">SKU</div>
                  <div class="col-2">Title</div>
                  <div class="col-2">Dosage</div>
                  <div class="col-2">Price</div>
                  <div class="col">Comments</div>
                  <div class="col-1 noprint" v-if="isSelected(item.id)"></div>
              </div>
              <div class="sw-row d-flex" v-for="(i, key) in item.data" :key="key">
                <div class="col-2 longTextWrap" v-if="editItem !== i">{{ i.sku }}</div>
                <div class="col-2" v-else>
                  <input
                      class="form-control form-control-sm mb-3"
                      type="text"
                      v-model="i.sku"
                    />
                </div>
                <div class="col-2 longTextWrap" v-if="editItem !== i">{{ i.title }}</div>
                <div class="col-2" v-else>
                  <input
                    class="form-control form-control-sm mb-3"
                    type="text"
                    v-model="i.title"
                  />
                </div>
                <div class="col-2 longTextWrap" v-if="editItem !== i">{{ i.dosage }}</div>
                <div class="col-2" v-else>
                  <input
                    class="form-control form-control-sm mb-3"
                    type="text"
                    v-model="i.dosage"
                  />
                </div>
                <div class="col-2" v-if="editItem !== i">£{{ i.price }}</div>
                <div class="col-2" v-else>
                  <input
                    class="form-control form-control-sm mb-3"
                    type="text"
                    v-model="i.price"
                  />
                </div>
                <div class="col longTextWrap" v-if="editItem !== i">{{ i.comments }}</div>
                <div class="col" v-else>
                  <textarea
                    class="form-control form-control-sm mb-3"
                    type="text"
                    v-model="i.comments"
                    rows="4"
                  />
                </div>
                <div class="noprint col-1" v-if="isSelected(item.id)">
                  <button
                    class="btn btn-xs btn-light"
                    @click="editRow(i)"
                    v-if="editItem !== i"
                  >
                    Edit
                  </button>
                  <button
                    class="btn btn-xs btn-light"
                    @click="editRow(i)"
                    v-else
                  >
                    Save
                  </button>
                  <button
                    class="btn btn-xs btn-light"
                    @click="deleteTableRow(i)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="isSelected(item.id)">
            <button
              class="btn btn-xs btn-info mb-2"
              v-on:click="toggleFeature = !toggleFeature"
              v-tooltip.bottom="'Add a supplement from your library'"
            >
              <i class="fas fa-dot-circle me-2 pull-right"></i>
              <span v-show="toggleFeature">Hide</span>
              <span v-show="!toggleFeature">Add Supplements</span>
            </button>
            <SupplementsSuggestions v-if="toggleFeature" :item="item"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SupplementsSuggestions from "./suggestions/Supplements";

export default {
  props: ["item", "colKey"],
  data() {
    return {
      dtas: [],
      supplements: [],
      editItem: null,
      categories: [],
      selectedCategory: 0,
      toggleFeature: false,
    };
  },
  methods: {
    editRow(item) {
      if (this.editItem == item) {
        this.editItem = null;
        this.updateTemplate();
      } else {
        this.editItem = item;
      }
    },
    deleteTableRow(item) {
      var indexOf = this.item.data.findIndex((x) => x.id == item.id);
      this.item.data.splice(indexOf, 1);
      this.$EventBus.$emit("saveTemplate");
    },
    getSupplements() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/supplements")
        .then(({ data }) => {
          this.supplements = data;
        });
    },
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      var tclass = this.isSelected(itemId) ? "border-danger" : "border-light";
      return tclass;
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    deleteItemFromCol(item) {
      if (
        !confirm(
          "Are you sure you wish to remove this item and all its contents?"
        )
      ) {
        return;
      }
      var data = {
        colKey: this.colKey,
        item: item,
      };
      var indexOfRowToRemoveFrom = this.$stash.templateItems
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$stash.currentSelectedRow.id);

      var templateItems = this.$stash.templateItems;
      var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

      var indexToRemove = rowToAddTo[data.colKey].items
        .map(function (x) {
          return x.id;
        })
        .indexOf(data.item.id);

      rowToAddTo[data.colKey].items.splice(indexToRemove, 1);
      this.$EventBus.$emit("saveTemplate");
    },
    updateTemplate() {
      this.$EventBus.$emit("saveTemplate");
    },
    addSupplement(data, event_id) {
      if(event_id == this.item.id) {
        this.item.data.push(data);
        this.$EventBus.$emit("saveTemplate");
      }
    }
  },
  mounted: function () {
    this.getSupplements();
  },
  components: {
    SupplementsSuggestions,
  },
  created() {
    this.$EventBus.$on("addSupplement", this.addSupplement);
  },
  beforeDestroy(){
    this.$EventBus.$off("addSupplement", this.addSupplement);
  }
};
</script>

<style scoped>
.floating-remove-table-row {
  position: absolute;
  right: 0.25rem;
  top: 0rem;
}
.rowCol-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}
.fixed-table {
  table-layout: fixed;
  width: 100%;
}

.fixedScrollTable {
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #03006b white;
  cursor: pointer;
}
/* Works on Chrome/Edge/Safari */
.fixedScrollTable::-webkit-scrollbar {
  width: 12px;
}
.fixedScrollTable::-webkit-scrollbar-track {
  background-image: white;
}
.fixedScrollTable::-webkit-scrollbar-thumb {
  background-color: #413dc2;
  border-radius: 20px;
  border: 3px solid #03006b;
}

.longTextWrap {
  word-break: break-word;
}
</style>