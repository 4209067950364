<template>
  <div class="row noprint">
    <div class="col-md-12">
      <hr/>
      <div class="row">
        <div class="col-md-12 form-inline">
          <input
            type="text"
            @input="debounceSearch"
            v-model="search"
            class="form-control form-control-sm mb-3 w-50"
            placeholder="Supplement search..."
          />
          <select
            placeholder="Select Category"
            class="form-control form-control-sm mb-3 ms-3 w-25"
            v-model="selectedCategory"
          >
            <option value="0">View All</option>
            <option
              v-for="category in categories"
              :key="category.name"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>
          <button class="btn mb-3 ms-2" @click="resetSearch()">
            <i class="fas fa-sync-alt"></i> Reset
          </button>
          </div>
      </div>
          <table class="table table-hover noprint">
            <thead>
              <th scope="col" class="center">Find Supplements</th>
              <th></th>
            </thead>
            <tbody class="fixedScrollTable w-100">
              <tr
                class="d-flex"
                v-for="supplement in filteredByCategory"
                :key="supplement.id"
                @click="selectSupplement(supplement)"
              >
                <td class="col-6 textItem longTextWrap">
                  {{ supplement.sku }}
                </td>
                <td class="col-6 textItem longTextWrap">
                  {{ supplement.title }}
                </td>
              </tr>
            </tbody>
          </table>

    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      supplements: [],
      categories: [],
      selectedCategory: 0,
      search: "",
    };
  },
  methods: {
    debounceSearch: _.debounce(function () {
      this.findSupplement();
    }, 800),
    resetSearch() {
      this.selectedCategory = 0;
      this.search = "";
      this.findSupplement();
    },
    changeCategory(id) {
      this.selectedCategory = id;
    },
    getCategories() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/supplements/categories")
        .then(({ data }) => {
          this.categories = data.data;
        });
    },
    selectSupplement(supplement) {
      this.$EventBus.$emit("addSupplement", supplement, this.item.id);
      this.$EventBus.$emit("saveTemplate");
    },
    getSupplements() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/supplements")
        .then(({ data }) => {
          this.supplements = data;
        });
    },
    findSupplement() {
      if (this.search.length > 0) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/supplements/search", {
            search: this.search,
          })
          .then(({ data }) => {
            this.supplements = data;
          });
      } else {
        this.supplements = [];
        this.getSupplements();
      }
    },
  },
  mounted: function () {
    this.getCategories();
    this.getSupplements();
  },
  computed: {
    filteredByCategory: function () {
      var items = [];
      items = this.supplements.filter((item) => {
        if (item.category_id == this.selectedCategory) {
          return true;
        }

        if (this.selectedCategory == 0) {
          return true;
        }

        return false;
      });
      return items;
    },
  },

};
</script>

<style scoped>
.longTextWrap {
  word-break: break-word;
}

.fixedScrollTable {
  font-size: 12px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #03006b white;
  cursor: pointer;
}
/* Works on Chrome/Edge/Safari */
.fixedScrollTable::-webkit-scrollbar {
  width: 12px;
}
.fixedScrollTable::-webkit-scrollbar-track {
  background-image: white;
}
.fixedScrollTable::-webkit-scrollbar-thumb {
  background-color: #413dc2;
  border-radius: 20px;
  border: 3px solid #03006b;
}
</style>