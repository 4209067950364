<template>
  <div
    class="mb-3 border p-2"
    style="position:relative"
    @click="selectThisItem"
    :class="isTheItemSelected(item.id)"
  >
    <span
      style="position: absolute; bottom: 0rem; right: .15rem; z-index: 999;"
      v-tooltip.bottom="'Delete this item'"
    >
      <button class="btn btn-xs btn-light noprint" @click="deleteItemFromCol(item)" v-if="isSelected(item.id)">
        <i class="far fa-trash"></i>
      </button>
    </span>
    <div :class="'text-' + item.options.align">
      <h5 class="mb-2" v-show="item.label" v-if="!isSelected(item.id)">
        {{ item.label }}
      </h5>
      <input v-else
        class="form-control form-control-sm mb-3 noprint"
        type="text"
        v-model="item.label"
      />

      <div v-if="isSelected(item.id)">
        <div class="row">
          <div class="col">
            <div class="noprint">
              <vue-editor
                v-model="item.data"
                class="bg-white"
                :placeholder="item.label"
                :editor-toolbar="customToolbar"
                @focus="updateTemplate"
              ></vue-editor>
            </div>
            <div class="show-when-printing">
              <div v-html="item.data"></div>
            </div>
            <div class="row text-start noprint">
              <div class="col">
                <button
                  v-if="item.data && item.data.length > 3"
                  class="btn btn-xs btn-info"
                  v-on:click="toggleAS = !toggleAS"
                  v-tooltip.bottom="'Toggle the auto-suggestion feature set.'"
                >
                  <i class="fas fa-dot-circle me-2 pull-right"></i>
                  <span v-show="toggleAS">Hide</span>
                  <span v-show="!toggleAS">Show</span> Auto Suggestions
                </button>
              </div>
            </div>
          </div>
        </div>
        <AutoSuggestions
          v-show="toggleAS"
          v-if="item.data && item.data.length > 3"
          :textEntry="item"
        />
      </div>
      <div class="ql-editor non-editor hr-font" v-html="item.data" v-else></div>
    </div>
  </div>
</template>

<script>
import AutoSuggestions from "./AutoSuggestions.vue";

export default {
  props: ["item", "colKey"],
  data() {
    return {
      dtas: [],
      toggleAS: false,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["clean"],
      ],
    };
  },
  components: {
    AutoSuggestions,
  },
  methods: {
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      var tclass = this.isSelected(itemId) ? "border-danger" : "border-light";
      return tclass;
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    deleteItemFromCol(item) {
      if (!confirm("Are you sure you wish to remove this item and all its contents?")) {
        return;
      }
      var data = {
        colKey: this.colKey,
        item: item,
      };
      var indexOfRowToRemoveFrom = this.$stash.templateItems
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$stash.currentSelectedRow.id);

      var templateItems = this.$stash.templateItems;
      var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

      var indexToRemove = rowToAddTo[data.colKey].items
        .map(function (x) {
          return x.id;
        })
        .indexOf(data.item.id);

      rowToAddTo[data.colKey].items.splice(indexToRemove, 1);
      this.$EventBus.$emit("saveTemplate");
    },
    updateTemplate() {
      this.$EventBus.$emit("saveTemplate");
    },
  },
};
</script>

<style scoped>
</style>