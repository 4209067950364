<template>
  <div
    class="row border p-2 unmargin"
    :id="'row-' + row.id"
    :class="isTheRowSelected()"
    @click.stop="selectedRow"
  >
    <div class="col unmargin">
      <!--  -->
      <div class="row unmargin">
        <template v-if="row.items.length > 0">
          <template v-for="(col, colKey) in row.items">
            <!--  -->
            <dt-col
              :key="colKey"
              :row="row"
              :col="col"
              :col-key="colKey"
              :client="client"
            ></dt-col>
            <!--  -->
          </template>
        </template>
      </div>
      <!--  -->

      <div class="row text-center p-3 align-items-center" v-if="row.items.length == 0">
        <i class="far fa-plus fa-10x mb-3"></i>
        <h5>Add item to row - click in to select</h5>
      </div>
    </div>

    <span class="floating-row-delete noprint">
      <button
        class="btn btn-xs btn-light"
        v-tooltip.bottom="'Delete this row & all its items'"
        @click.stop="deleteRow(row)"
      >
        <i class="far fa-trash"></i>
      </button>
    </span>
  </div>
</template>

<script>
import DtCol from "./DtCol";

export default {
  props: ["row", "client"],
  data() {
    return {
      currentSelectedRow: this.$stash.currentSelectedRow
    };
  },
  methods: {
    deleteRow(row) {
      if (
        confirm(
          "Are you sure you wish to remove this row and all its contents? This cannot be undone!?"
        )
      ) {
        var indexToDelete = this.$stash.templateItems.findIndex(
          x => x.id === row.id
        );
        this.$stash.templateItems.splice(indexToDelete, 1);

        this.$stash.currentSelectedColKey = null;
        this.$stash.currentSelectedCol = null;
        this.$stash.currentSelectedItem = null;
        this.$stash.currentSelectedRow = null;
        this.$EventBus.$emit("saveTemplate");
      }
    },
    selectedRow() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedRow = this.row;
      this.$stash.currentSelectedColKey = this.$stash.currentSelectedColKey
        ? this.$stash.currentSelectedColKey
        : 0;
    },
    isTheRowSelected() {
      if (
        this.$stash.currentSelectedRow &&
        this.$stash.currentSelectedRow.id === this.row.id
      ) {
        return "border-primary";
      }

      return "border-light";
    }
  },
  watch: {
    currentSelectedRow: {
      handler: function(val, oldVal) {
        this.isTheRowSelected();
      }
    }
  },
  components: {
    DtCol
  }
};
</script>

<style>
.floating-row-delete {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 9999;
}
</style>