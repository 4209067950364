<template>
  <div
    class="mb-3 border p-3"
    style="position: relative"
    @click="selectThisItem"
    :class="isTheItemSelected(item.id)"
  >
    <span
      style="position: absolute; bottom: 0.15rem; right: 0.15rem; z-index: 999"
      v-tooltip.bottom="'Delete this item'"
    >
      <button
        class="btn btn-xs btn-light noprint"
        @click="deleteItemFromCol(item)"
        v-if="isSelected(item.id)"
      >
        <i class="far fa-trash"></i>
      </button>
    </span>
    <div :class="'text-' + item.options.align">
      <h2 :class="'mb-2 Header' + item.options.textSize + ' Header-' + item.options.textWeight" v-show="item.label" v-if="!isSelected(item.id)">
        {{ item.label }}
      </h2>
      <input v-else
        :class="'form-control form-control-sm mb-3 noprint text-' + item.options.align"
        type="text"
        v-model="item.label"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "colKey"],
  methods: {
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      var tclass = this.isSelected(itemId) ? "border-danger" : "border-light";
      return tclass;
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    deleteItemFromCol(item) {
      var data = {
        colKey: this.colKey,
        item: item,
      };
      var indexOfRowToRemoveFrom = this.$stash.templateItems
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$stash.currentSelectedRow.id);

      var templateItems = this.$stash.templateItems;
      var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

      var indexToRemove = rowToAddTo[data.colKey].items
        .map(function (x) {
          return x.id;
        })
        .indexOf(data.item.id);

      rowToAddTo[data.colKey].items.splice(indexToRemove, 1);
      this.$EventBus.$emit("saveTemplate");
    },
  },
};
</script>

<style scoped>
/* HEADER textSize Option */
.HeaderSmall {
  font-size: 0.8rem;
}
.HeaderMedium {
  font-size: 1.125rem;
}
.HeaderLarge {
  font-size: 1.75rem;
}
.HeaderExtraLarge {
  font-size: 3rem;
}
.Header-lighter {
  font-weight: 100;
}
.Header-medium {
  font-weight: 400;
}
.Header-bold {
  font-weight: 700;
}
.Header-bolder {
  font-weight: 900;
}
</style>