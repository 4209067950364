<template>
  <div>
    <div class="w-100 noprint" v-if="!miniview">
      <div class="card border-0 shadow noprint">
        <div class="card-header bg-secondary text-white">
          <button class="btn" v-if="showLayout" @click="showLayout = false">
            <i class="fas fa-minus-square text-white"></i>
          </button>
          <button class="btn" @click="showLayout = true" v-else>
            <i class="fas fa-plus-square text-white"></i>
          </button>
          Layout
        </div>
        <div class="card-body noprint" v-if="showLayout">
          <ul class="list-group list-group-flush">
            <div v-for="(item, key) in draggableItems" :key="key">
              <form-builder-item
                :item="item"
                v-if="itemCheck(item, 'layout')"
              ></form-builder-item>
            </div>
          </ul>
        </div>
      </div>
      <div
        class="card border-0 shadow noprint mt-3"
        v-if="$stash.currentSelectedRow"
      >
        <div class="card-header bg-secondary text-white">
          <button class="btn" v-if="showObjects" @click="showObjects = false">
            <i class="fas fa-minus-square text-white"></i>
          </button>
          <button class="btn" @click="showObjects = true" v-else>
            <i class="fas fa-plus-square text-white"></i>
          </button>
          Insert Items
        </div>
        <div class="card-body noprint" v-if="showObjects">
          <ul class="list-group list-group-flush mb-3">
            <div v-for="(item, key) in draggableItems" :key="key">
              <form-builder-item
                :item="item"
                v-if="itemCheck(item, 'objects')"
              ></form-builder-item>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="dropdown">
  <button class="btn btn-secondary " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
     <i class="fas fa-plus-square text-white"></i>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <!--a v-for="(item, key) in draggableItems" :key="key" class="dropdown-item" href="#"><i :class="'w-25 fa ' + item.icon"></i> {{item.name}}</a-->
    <div v-for="(item, key) in draggableItems" :key="key">
              <form-builder-mini-item
                :item="item"
                v-if="itemCheck(item, 'objects')"
              ></form-builder-mini-item>
            </div>
  </div>
</div>
    </div>
  </div>
</template>

<script>
import FormBuilderItem from "./item-partials/FormBuilderItem";
import FormBuilderMiniItem from "./item-partials/FormBuilderMiniItem";

export default {
  props: ["client", "template", "resources", "miniview"],
  data() {
    return {
      showObjects: true,
      showLayout: true,
      showAttatchments: true,
      draggableItems: [
        {
          id: 1,
          name: "Row",
          type: "row",
          icon: "fa-horizontal-rule",
          items: [
            {
              id: 10,
              name: "Column",
              type: "col",
              icon: "fa-columns",
              items: [],
            },
          ],
        },
        {
          id: 2,
          name: "Column",
          type: "col",
          icon: "fa-columns",
          items: [],
        },

        {
          id: 6,
          name: "Clinic Logo",
          type: "logo",
          icon: "fa-image",
          clinic_name: this.$store.getters['auth/user'].clinic.name,
          clinic_logo_url: this.$store.getters['auth/user'].clinic.logo,
          options: {
            align: "center",
            header: true,
          },
        },

        {
          id: 10,
          name: "Image",
          type: "image",
          icon: "fa-file-image",
          img_alt: "",
          img_url: "",
          options: {
            align: "center",
            scale: 80,
            pageBreak: "default",
          },
        },
        {
          id: 7,
          name: "Heading",
          type: "heading",
          icon: "fa-h1",
          items: [],
          data: [],
          label: "Sample heading",
          options: {
            align: "left",
            textSize: "Medium",
            textWeight: "medium",
          },
        },
        {
          id: 5,
          name: "Free Text",
          type: "text",
          icon: "fa-text",
          data: "",
          label: "Label for this text area - can be removed",
          options: {
            border: 0,
            bordercol: "dark",
            pageBreak: "default",
          },
        },
        {
          id: 3,
          name: "List",
          type: "ul",
          icon: "fa-list-ul",
          items: [],
          label: "Label for this list - can be removed",
          data: ["Sample option 1", "Sample option 2", "Sample option 3"],
          options: {
            align: "left",
            list: "bullet",
            pageBreak: "",
          },
        },
        {
          id: 9,
          name: "Supplements Table",
          type: "supplements-table",
          label: "Supplements",
          icon: "fa-table",
          data: [
            {
              sku: "1234",
              title: "Some title here",
              dosage: "Take 6 daily",
              comments: "Some cool commentary in here",
              price: 12.52,
            },
            {
              sku: "56789",
              title: "Ereeeee title here",
              dosage: "Take 92 daily",
              comments: "Some commentary in here",
              price: 150.54,
            },
          ],
          options: {
            align: "left",
            pageBreak: "default",
          },
        },
        {
          id: 11,
          name: "Tests Table",
          type: "tests-table",
          label: "Tests",
          icon: "fa-vial",
          data: [
            {
              code: "1234",
              type: "TestType",
              supplier: "Supplier X",
              description: "This is a description of the test",
              price: 12.52,
              comments: "Some cool commentary in here",
            },
            {
              code: "1235",
              type: "TestType",
              supplier: "Supplier Y",
              description: "This is also a description of the test",
              price: 20.0,
              comments: "Some cool commentary in here",
            },
          ],
          options: {
            align: "left",
            pageBreak: "default",
          },
        },
        {
          id: 12,
          name: "Page Break",
          type: "page-break",
          label: "Page break",
          icon: "fa-horizontal-rule",
        },

        // {
        //   id: 8,
        //   name: this.$store.getters['auth/user'].name,
        //   type: "practitioner-name",
        //   icon: "fa-user",
        //   data: this.$store.getters['auth/user'].name,
        //   hover: "Will display your name",
        //   options: {
        //     align: "left"
        //   }
        // },
        // {
        //   id: 9,
        //   name: "Client Name",
        //   type: "client-name",
        //   icon: "fa-user",
        //   options: {
        //     align: "left"
        //   }
        // }
      ],
    };
  },
  methods: {
    removeResource(r) {
      this.$EventBus.$emit("removeResource", r);
      r.attached = false;
    },
    removeFolder(r) {
      let vm = this;
      this.resources.forEach((item) => {
        if (item.folder === r.id) {
          vm.removeResource(item);
        }
      });
      vm.removeResource(r);
    },
    shareResources() {
      var clients = [];
      clients.push(this.$stash.loadedTemplate.client);
      this.$axios.post(process.env.VUE_APP_API_URL + "/resources/share-multi", {
        clients: clients,
        resources: this.resources,
      });
    },
    downloadResource(resource) {
      window.open(resource.resource.file, "_blank");
    },
    viewFolder(modal) {
      this.$refs[modal][0].openModal();
    },
    saveTemplate() {
      var silent = false;
      this.$EventBus.$emit("saveTemplate", silent);
    },
    itemCheck(item, menu) {
      if (menu === "layout") {
        if (item.type == "row") {
          return true;
        }
        if (item.type == "col") {
          if (
            this.$stash.currentSelectedRow &&
            this.$stash.currentSelectedRow.items.length < 3
          ) {
            return true;
          }
        }
      } else if (menu === "objects") {
        if (item.type == "ul") {
          return true;
        }
        if (item.type == "ol") {
          return true;
        }
        if (item.type == "text") {
          return true;
        }
        if (item.type == "logo") {
          return true;
        }
        if (item.type == "page-break") {
          return true;
        }

        if (item.type == "image") {
          return true;
        }
        if (item.type == "heading") {
          return true;
        }
        if (item.type == "practitioner-name") {
          return true;
        }
        if (item.type == "text-passage") {
          return true;
        }

        if (item.type == "supplements-table") {
          return true;
        }

        if (item.type == "tests-table") {
          return true;
        }
      }

      return false;
    },
    checkForCols(item) {
      if (
        item.type == "col" &&
        this.$stash.currentSelectedRow.items.length <= 3
      ) {
        return false;
      }
      return true;
    },
    clearCurrentSelection() {
      this.$stash.currentSelectedRow = null;
      this.$stash.currentSelectedColKey = null;
      this.$stash.currentSelectedCol = null;
      this.$stash.currentSelectedItem = null;
    },
    folderItems(folder) {
      return this.resources.filter((r) => {
        return r.folder === folder.id;
      });
    },
  },
  mounted() {
    this.$EventBus.$on("clearSelectedTemplateItems", () => {
      this.clearCurrentSelection();
    });
    this.$EventBus.$on("shareResources", this.shareResources);
  },
  beforeDestroy() {
    this.$EventBus.$off("shareResources", this.shareResources);
  },
  computed: {
    rowCount() {
      return this.$stash.templateItems.filter(function (item) {
        return item.type === "row";
      }).length;
    },
  },
  components: {
    FormBuilderItem,
    FormBuilderMiniItem
  },
};
</script>

<style scoped>
.resource-item {
  text-align: center;
}
</style>
