<template>
  <div>

    <div class="card border-0 shadow-none">
      <div class="card-header bg-secondary text-white">
        <button class="btn" v-if="show" @click="show=false"><i class="fas fa-minus-square text-white" ></i> </button>
        <button class="btn" @click="show=true" v-else><i class="fas fa-plus-square text-white" ></i></button>
        Edit: {{ $stash.currentSelectedItem.name }}
        </div>
      <div class="card-body" v-if="show">
        <!--
        <template v-if="$stash.currentSelectedItem.label || $stash.currentSelectedItem.label == ''">
          <div class="row mb-3">
            <div class="col">
              <label for><small>Label</small></label>
              <input
                type="text"
                v-model="$stash.currentSelectedItem.label"
                class="form-control form-control-sm"
                @change="updateTemplate"
              />
            </div>
          </div>
        </template>
        Label -->

        <!-- Logo Header -->
        <template
          v-if="$stash.currentSelectedItem.options.header || $stash.currentSelectedItem.options.header == false"
        >
          <div class="row mb-3">
            <div class="col">
              <label for><small>Show Clinic Name (under logo)</small></label>
              <select
                v-model="$stash.currentSelectedItem.options.header"
                class="form-control form-control-sm"
                @change="updateTemplate"
              >
                <option :value="true">Show</option>
                <option :value="false">Hide</option>
              </select>
            </div>
          </div>
        </template>

        <!-- Alignment -->
        <template v-if="$stash.currentSelectedItem.options.align">
          <div class="row mb-3">
            <div class="col">
              <label for><small>Alignment</small></label>
              <select
                v-model="$stash.currentSelectedItem.options.align"
                class="form-control form-control-sm"
                @change="updateTemplate"
              >
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
            </div>
          </div>
        </template>

        <!-- Text Size -->
        <template v-if="$stash.currentSelectedItem.options.textSize">
          <div class="row mb-3">
            <div class="col">
              <label for><small>Text Size</small></label>
              <select
                v-model="$stash.currentSelectedItem.options.textSize"
                class="form-control form-control-sm"
                @change="updateTemplate"
              >
                <option value="Small">Small</option>
                <option value="Medium">Medium</option>
                <option value="Large">Large</option>
                <option value="ExtraLarge">Extra Large</option>
              </select>
            </div>
          </div>
        </template>

        <!-- Text Weight -->
        <template v-if="$stash.currentSelectedItem.options.textWeight">
          <div class="row mb-3">
            <div class="col">
              <label for><small>Font Weight</small></label>
              <select
                v-model="$stash.currentSelectedItem.options.textWeight"
                class="form-control form-control-sm"
                @change="updateTemplate"
              >
                <option value="lighter">Light</option>
                <option value="normal">Standard</option>
                <option value="bold">Bold</option>
                <option value="bolder">Extra Bold</option>
              </select>
            </div>
          </div>
        </template>

        <!-- Image Size -->
        <template v-if="$stash.currentSelectedItem.options.scale">
          <div class="row mb-3">
            <div class="col">
              <label for><small>Image Scale</small></label>
                <input @change="updateTemplate"
                v-model="$stash.currentSelectedItem.options.scale"
                class="form-control form-control-sm slider"
                type="range" min="1" max="100" >
            </div>
          </div>
        </template>

        <!-- List Type -->
        <template v-if="$stash.currentSelectedItem.options.list">
          <div class="row mb-3">
            <div class="col">
              <label for><small>List Type</small></label>
              <select
                v-model="$stash.currentSelectedItem.options.list"
                class="form-control form-control-sm"
                @change="updateTemplate"
              >
                <option value="bullet">Bullet</option>
                <option value="number">Numbered</option>
              </select>
            </div>
          </div>
        </template>

        <!-- Border Size -->
        <template v-if="$stash.currentSelectedItem.options.border >= 0">
          <div class="row mb-3">
            <div class="col">
              <label for><small>Border </small>
              <small v-if="$stash.currentSelectedItem.options.border == 0">[OFF]</small>
              <small v-else>[{{$stash.currentSelectedItem.options.border}}px]</small>
              </label>
                <input @change="updateTemplate"
                v-model="$stash.currentSelectedItem.options.border"
                class="form-control form-control-sm slider"
                type="range" min="0" max="3" >
            </div>
            <div class="col">
              <label for><small>Colour</small></label>
                <select
                v-model="$stash.currentSelectedItem.options.bordercol"
                class="form-control form-control-sm"
                @change="updateTemplate"
              >
                <option value="dark">Dark</option>
                <option value="danger">Red</option>
                <option value="warning">Yellow</option>
                <option value="success">Green</option>
              </select>
            </div>
          </div>
        </template>

        <!-- Page Break -->
        <template v-if="$stash.currentSelectedItem.options.pageBreak">
          <div class="row mb-3">
            <div class="col">
              <label for><small>Page Breaks</small></label>
              <select
                v-model="$stash.currentSelectedItem.options.pageBreak"
                class="form-control form-control-sm"
                @change="updateTemplate"
              >
                <option value="default">Default</option>
                <option value="nobreak">Prevent Breaking</option>
              </select>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      templateTitle: ""
    };
  },
  methods: {
    updateTemplate() {
      this.$EventBus.$emit("saveTemplate");
    }
  }
};
</script>

<style>
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #03006b;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #03006b;
  cursor: pointer;
}

</style>