<template>
  <div
    class="mb-3 border p-2"
    style="position: relative"
    @click="selectThisItem"
    :class="isTheItemSelected(item.id)"
  >
    <span
      style="position: absolute; bottom: 0rem; right: 0.15rem; z-index: 999"
      v-tooltip.bottom="'Delete this item'"
    >
      <button
        class="btn btn-xs btn-light noprint"
        @click="deleteItemFromCol(item)"
        v-if="isSelected(item.id)"
      >
        <i class="far fa-trash"></i>
      </button>
    </span>
    <div
      class="testing-table-printing nobreak"
      :class="'text-' + item.options.align"
    >
      <h5 class="mb-2" v-show="item.label" v-if="!isSelected(item.id)">
        {{ item.label }}
      </h5>
      <input
        v-else
        class="form-control form-control-sm mb-3 noprint"
        type="text"
        v-model="item.label"
      />
      <div>
        <div class="row">
          <div class="col">
            <div class="container-fluid sw-table health-report hr-font hr-table">
              <div class="sw-row d-flex fw-bold">
                  <div class="col-2">Code+Type</div>
                  <div class="col-2">Supplier</div>
                  <div class="col-2">Description</div>
                  <div class="col-2">Price</div>
                  <div class="col">Comments</div>
                  <div class="noprint col-1"  v-if="isSelected(item.id)"></div>
              </div>
              <div class="sw-row d-flex longTextWrap" v-for="(i, key) in item.data" :key="key">
                  <div class="col-2" v-if="editItem !== i">{{ i.code }}<br/>{{ i.type }}</div>
                  <div class="col-2" v-else>
                    <input
                      class="form-control form-control-sm mb-3"
                      type="text"
                      v-model="i.code"
                    />
                    <input
                      class="form-control form-control-sm mb-3"
                      type="text"
                      v-model="i.type"
                    />
                  </div>
                  <div class="col-2 longTextWrap" v-if="editItem !== i">{{ i.supplier }}</div>
                  <div class="col-2" v-else>
                    <input
                      class="form-control form-control-sm mb-3"
                      type="text"
                      v-model="i.supplier"
                    />
                  </div>
                  <div class="col-2 longTextWrap" v-if="editItem !== i">{{ i.description }}</div>
                  <div class="col-2" v-else>
                    <input
                      class="form-control form-control-sm mb-3"
                      type="text"
                      v-model="i.description"
                    />
                  </div>
                  <div class="col-2" v-if="editItem !== i">£{{ i.price }}</div>
                  <div class="col-2" v-else>
                    <input
                      class="form-control form-control-sm mb-3"
                      type="text"
                      v-model="i.price"
                    />
                  </div>
                  <div class="col longTextWrap" v-if="editItem !== i" style="word-break: break-word;">{{ i.comments }}</div>
                  <div class="col" v-else>
                    <textarea
                    class="form-control form-control-sm mb-3"
                    type="text"
                    v-model="i.comments"
                    rows="4"
                  />
                  </div>
                  <div class="noprint col-1" v-if="isSelected(item.id)">
                    <button
                      class="btn btn-xs btn-light"
                      @click="editRow(i)"
                      v-if="editItem !== i"
                    >
                      Edit
                    </button>
                    <button
                      class="btn btn-xs btn-light"
                      @click="editRow(i)"
                      v-else
                    >
                      Save
                    </button>
                    <button
                      class="btn btn-xs btn-light"
                      @click="deleteTableRow(i)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <TestsSuggestions v-if="isSelected(item.id)" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import TestsSuggestions from "./suggestions/Tests";

export default {
  props: ["item", "colKey"],
  data() {
    return {
      dtas: [],
      testings: [],
      editItem: null,
    };
  },
  methods: {
    editRow(item) {
      if (this.editItem == item) {
        this.editItem = null;
        this.updateTemplate();
      } else {
        this.editItem = item;
      }
    },
    deleteTableRow(item) {
      var indexOf = this.item.data.findIndex((x) => x.id == item.id);
      this.item.data.splice(indexOf, 1);
      this.$EventBus.$emit("saveTemplate");
    },
    isSelected(itemId) {
      if (
        this.$stash.currentSelectedItem &&
        this.$stash.currentSelectedItem.id == itemId
      ) {
        return true;
      }

      return false;
    },
    isTheItemSelected(itemId) {
      var tclass = this.isSelected(itemId) ? "border-danger" : "border-light";
      return tclass;
    },
    selectThisItem() {
      this.$stash.unsavedChanges = true;
      this.$stash.currentSelectedItem = null;
      this.$stash.currentSelectedItem = this.item;
    },
    deleteItemFromCol(item) {
      if (!confirm("Are you sure you wish to remove this item and all its contents?")) {
        return;
      }
      var data = {
        colKey: this.colKey,
        item: item,
      };
      var indexOfRowToRemoveFrom = this.$stash.templateItems
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.$stash.currentSelectedRow.id);

      var templateItems = this.$stash.templateItems;
      var rowToAddTo = templateItems[indexOfRowToRemoveFrom].items;

      var indexToRemove = rowToAddTo[data.colKey].items
        .map(function (x) {
          return x.id;
        })
        .indexOf(data.item.id);

      rowToAddTo[data.colKey].items.splice(indexToRemove, 1);
      this.$EventBus.$emit("saveTemplate");
    },
    updateTemplate() {
      this.$EventBus.$emit("saveTemplate");
    },
    addTest(data, event_id) {
      if(event_id == this.item.id) {
        this.item.data.push(data);
        this.$EventBus.$emit("saveTemplate");
      }
    }
  },
  created () {
    this.$EventBus.$on("addTest", this.addTest);
  },
  beforeDestroy(){
    this.$EventBus.$off("addTest", this.addTest);
  },
  components: {
    TestsSuggestions
  }
};
</script>

<style>
.floating-remove-table-row {
  position: absolute;
  right: 0.25rem;
  top: 0rem;
}
.rowCol-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}
.fixed-table {
  table-layout: fixed;
  width: 100%;
}

.longTextWrap {
  word-break: break-word;
}
</style>